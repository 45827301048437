<template>
  <div class="onlineRecovery">
        <van-nav-bar
       left-arrow
       fixed
       title="线上回收流程"
       safe-area-inset-top
       placeholder 
       @click-left="leftClick"
        />
        <div v-if="loading">
            <center id="loadingbox">
              <van-loading  size="70px" />
            </center>
        </div>
        <img :src="img"  v-else alt="">
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
    data() {
        return {
            // 照片路径
            img:"",
            // 加载状态
            loading:true
        }
    },
    methods:{
        // 顶部左侧箭头点击事件
        leftClick(){
            this.$router.back()
        }
    },
  created(){
        this.$nextTick(async()=>{
            // 发请求获取照片路径
                let res = await api.getFlowChart("MOBILE_PWA")
                res.forEach(item=>{
                    if (item.eventType == "RECYCLE_DETAIL") {
                        // 关闭加载状态
                         this.loading = false
                        //  路径赋值
                         this.img = item.img
                    }
                })
            })
    }
}
</script>

<style lang="scss" scoped>
#loadingbox {
  margin-top: 150px;
}
 ::v-deep{
    .van-nav-bar{
        height: 90px;
        .van-nav-bar__content{
        line-height: 90px;
        height: 90px;
        .van-nav-bar__title{
            font-size: 36px;
        }
        .van-icon{
            font-size: 36px;
            color: #1e1e1e;
        }
        }
     }
 }
</style>