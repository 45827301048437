var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onlineRecovery" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          title: "线上回收流程",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.leftClick }
      }),
      _vm.loading
        ? _c(
            "div",
            [
              _c(
                "center",
                { attrs: { id: "loadingbox" } },
                [_c("van-loading", { attrs: { size: "70px" } })],
                1
              )
            ],
            1
          )
        : _c("img", { attrs: { src: _vm.img, alt: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }